import { Box, Chip, CircularProgress, Skeleton } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { api } from "../../../api/api";
import { CustomAccordion } from "../../../components/CustomAccordion/CustomAccordion";
import { Dialog } from "../../../components/Dialogs/Dialog";
import { useDispatchWrapper } from "../../../hooks";
import {
  defaultCustomer,
  fetchCustomerListAsync,
  ICustomer,
} from "../../../redux/customer";
import {
  ICustomerBranch,
  initialCustomerBranch,
} from "../../../redux/customerBranch";
import { IStoreState } from "../../../redux/initialStoreState";
import {
  IUserBillingInfo,
  saveUserBillingInfoInLocalStore,
} from "./UserBillingInfo.hooks";

interface ISelectCompanyInfoProps {
  open: boolean;
  onClose: () => void;
}

export const BillingInfoDialog: React.FC<ISelectCompanyInfoProps> = (props) => {
  const { open, onClose } = props;
  const [branches, setBranches] = React.useState<ICustomerBranch[]>([]);
  const [loadingBranch, setLoadingBranch] = React.useState<boolean>(false);
  const [billingInfo, setBillingInfo] = React.useState<IUserBillingInfo>({
    customer: defaultCustomer,
    customer_branch: initialCustomerBranch,
  });

  const {
    data: billingCompanyList,
    totalRecords,
    loading: billingCompanyLoading,
  } = useSelector((storeState: IStoreState) => storeState.customer.list);

  // const {
  // 	list: branchList,
  // 	totalRecords: branchTotoalRecords,
  // 	loading,
  // } = useSelector((storeState: IStoreState) => storeState.customerBranch);

  const dispatch = useDispatchWrapper();
  const defaultSearchQuery = {
    status: "ACTIVE",
    page: 1,
    rowsPerPage: 100,
    columns: [],
    value: "",
  };

  React.useEffect(() => {
    dispatch(fetchCustomerListAsync(defaultSearchQuery, 1));
  }, []);

  const handleAccordionChange =
    (customer: ICustomer) => async (event: React.SyntheticEvent) => {
      if (
        customer.customer_uuid &&
        billingInfo.customer.customer_uuid !== customer.customer_uuid
      ) {
        setLoadingBranch(true);
        setBillingInfo((prev) => ({
          ...prev,
          customer: customer,
        }));
        try {
          const res = await api.get(
            `/customer/get-customer-branch?customer_uuid=${customer.customer_uuid}&pageNo=1&itemPerPage=20`,
          );
          const data = res.data.data;
          if (data) {
            setBranches(data);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingBranch(false);
        }
      }
    };

  const handleSelectBranch = (branch: ICustomerBranch) => {
    setBillingInfo((prev) => ({
      ...prev,
      customer_branch: branch,
    }));
    if (branch) {
      saveUserBillingInfoInLocalStore(billingInfo.customer, branch);
    }
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        title="Select Billing Info"
        onClose={onClose}
        size="sm"
      >
        {billingCompanyLoading === 1 ? (
          <>
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          <>
            {billingCompanyList.map((item, index) => (
              <CustomAccordion
                key={item.customer_uuid}
                title={item.customer_name}
                pannelId="user_branch"
                isLight
                bottomGap
                onAccordionChange={handleAccordionChange(item)}
                expanded={
                  billingInfo.customer.customer_uuid === item.customer_uuid
                }
              >
                {loadingBranch ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <Box sx={{ display: "flex", gap: 0.5 }}>
                    {branches.length > 0
                      ? branches.map((branch) => (
                          <Chip
                            key={branch.customer_branch_uuid}
                            sx={{ cursor: "pointer" }}
                            label={`${branch.customer_branch_name} (${branch.customer_branch_gst_in ? branch.customer_branch_gst_in : "--"})`}
                            onClick={() => handleSelectBranch(branch)}
                          />
                        ))
                      : "No Branch Available"}
                  </Box>
                )}
              </CustomAccordion>
            ))}
          </>
        )}
      </Dialog>
    </>
  );
};
