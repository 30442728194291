import { LoadState } from "../../constants/enums";
import { defaultInvoiceItem } from "../invoice";
import { ICreditNote, ICreditNoteState } from "./types";

export const defaultCreditNote: ICreditNote = {
  credit_debit_note_uuid: null,
  credit_debit_note_type: "CREDIT",
  invoice_items: [defaultInvoiceItem],
  credit_debit_note_no: null,
  credit_debit_note_date: null,
  supplier_invoice_no: null,
  credit_debit_issue_date: null,
  invoice_id: null,
  invoice_date: null,
  return_month: null,
  return_quarter: null,
  place_of_supply: null,
  billing_company_uuid: "",
  billing_company_name: null,
  billing_company_branch_uuid: null,
  billing_company_branch_name: null,

  customer_type: "",
  customer_uuid: "",
  customer_name: "",
  customer_registration_type: null,
  customer_branch_uuid: "",
  customer_branch_name: "",
  customer_address_line1: null,
  customer_address_line2: null,
  customer_address_city: null,
  customer_address_state: null,
  customer_address_pincode: null,
  customer_gstin: "",
  customer_address_country: null,
  dispatch_address_line1: null,
  dispatch_address_line2: null,
  dispatch_address_city: null,
  dispatch_address_state: null,
  dispatch_address_pincode: null,
  dispatch_address_country: null,
  // delivery_challan_uuid: null,
  // delivery_challan_no: null,
  delivery_name: null,
  delivery_address_line1: null,
  delivery_address_line2: null,
  delivery_address_city: null,
  delivery_address_state: null,
  delivery_address_pincode: null,
  delivery_address_country: null,
  delivery_options: null,
  taxable_amount: 0,
  total_tax: 0,
  tcs: 0,
  tcs_amount: 0,
  discount: 0,
  discount_amount: 0,
  total_amount: 0,
  total_value: 0,
  total_amount_in_words: null,
  is_round_off: 0,
  combined_credit_debit_note_no: null,
  status: "ACTIVE",

  bank_name: null,
  bank_uuid: null,

  challan_date: null,
  challan_no: null,

  dispatch_through: null,
  document_type: "Regular",
  eway_bill_no: null,
  lr_no: null,
  note_type: "Goods Return",
  term_and_condition: null,
  transport_doc_date: null,
  transport_doc_no: null,
  transport_id: null,
  vehicle_no: null,
};

export const defaultCreditNoteState: ICreditNoteState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  creditNote: {
    data: defaultCreditNote,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
