import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { getSearchQuery } from "../common/helpers";
import { IQueryParams } from "../common/common.types";
import { IInvoice } from "./invoice.types";
import { uploadFile } from "../../helpers/uploadFile";
import {
  ICurrencyType,
  IPreviewType,
} from "../../views/quotes/Preview/InvoicePreview.types";

//=============================== Quotes List ===============================
export const FETCH_INVOICE_LIST_PROGRESS = "FETCH_INVOICE_LIST_PROGRESS";
export const FETCH_INVOICE_LIST_SUCCESS = "FETCH_INVOICE_LIST_SUCCESS";
export const FETCH_INVOICE_LIST_FAILED = "FETCH_INVOICE_LIST_FAILED";

export const fetchInvoiceListProgress = () =>
  action(FETCH_INVOICE_LIST_PROGRESS);
export const fetchInvoiceListSuccess = (
  list: IInvoice[],
  totalRecords: number,
) => action(FETCH_INVOICE_LIST_SUCCESS, { list, totalRecords });
export const fetchInvoiceListFailed = () => action(FETCH_INVOICE_LIST_FAILED);

export const fetchInvoiceListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchInvoiceListProgress());

      let url = "";
      console.log("queryParams.status", queryParams.status);

      // if (queryParams.status === "ACTIVE") {
      //   url += "&status=ACTIVE";
      // }
      const res = await api.get(`/invoice/get-invoice${searchQuery}`);
      const data: IInvoice[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchInvoiceListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchInvoiceListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message || "Unknown Error Occurred",
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Fetch Single Quote ===============================
export const FETCH_INVOICE_PROGRESS = "FETCH_INVOICE_PROGRESS";
export const FETCH_INVOICE_SUCCESS = "FETCH_INVOICE_SUCCESS";
export const FETCH_INVOICE_FAILED = "FETCH_INVOICE_FAILED";

export const fetchInvoiceProgress = () => action(FETCH_INVOICE_PROGRESS);
export const fetchInvoiceSuccess = (data: IInvoice) =>
  action(FETCH_INVOICE_SUCCESS, { data });
export const fetchInvoiceFailed = (errorMessage: string) =>
  action(FETCH_INVOICE_FAILED, { errorMessage });

export const fetchInvoiceAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchInvoiceProgress());
      const res = await api.get(`/invoice/get-invoice?invoice_uuid=${uuid}`);
      const data: IInvoice[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchInvoiceSuccess(data[0]));
      } else {
        dispatch(fetchInvoiceFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchInvoiceFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Fetch Single Public Invoice ===============================
export const fetchPublicInvoiceAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchInvoiceProgress());
      const res = await api.get(
        `/invoice/get-single-invoice?invoice_uuid=${uuid}`,
      );
      const data: IInvoice = res.data.data.quote;
      if (data) {
        dispatch(fetchInvoiceSuccess(data));
      } else {
        dispatch(fetchInvoiceFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchInvoiceFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Upsert Invoice ===============================
export const upsertInvoiceAsync =
  (
    data: IInvoice,
    file: any | null,
    onCallback: (
      isSuccess: boolean,
      invoiceUUID?: string,
      invoiceNo?: string,
      country?: string,
    ) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      let path = "";
      if (file && data.combined_invoice_no) {
        const asPayload = {
          invoice_no: data.combined_invoice_no,
          document_type: data.document_type,
        };
        path = await uploadFile(file, "INVOICE", data.qr_code || "", asPayload);
      }

      const {
        create_ts,
        insert_ts,
        invoice_no_aux,
        combined_invoice_no,
        delivery_challan_uuid,
        delivery_challan_no,
        ...payload
      } = data;
      const res = await api.post("/invoice/upsert-invoice", {
        ...payload,
        qr_code: path,
      });

      const responseData: IInvoice = res.data.data;

      onCallback(
        true,
        responseData.invoice_uuid as string,
        responseData.invoice_no,
        responseData.customer_address_country?.toLowerCase() as string,
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Invoice saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//=============================== Delete Invoice ===============================

export const deleteInvoiceAsync =
  (
    uuid: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.delete("/invoice/delete-invoice", {
        data: { invoice_uuid: uuid },
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Invoice deleted successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//=============================== Preview Invoice ===============================
export const fetchInvoicePreviewAsync =
  (
    uuid: string,
    previewType: IPreviewType,
    isIndianStyle: boolean,
    onCallback: (isSuccess: boolean, html?: any) => void,
    currency?: ICurrencyType,
    conversionRate?: number,
    isBankDetails?: boolean,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      let url = `/invoice/get-${previewType}-preview?invoice_uuid=${uuid}&isPreview=true&IndianStyle=${isIndianStyle}`;
      if (previewType === "INVOICE") {
        url += `&currency=${currency}&conversionRate=${conversionRate}&isBankDetails=${isBankDetails}`;
      }
      const res = await api.get(url);

      onCallback(true, res.data.data);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
      onCallback(false);
    }
  };

export const fetchInvoiceDownloadPdfAsync =
  (
    uuid: string,
    previewType: IPreviewType,
    isIndianStyle: boolean,
    onCallback: (isSuccess: boolean, html?: any) => void,
    currency?: ICurrencyType,
    conversionRate?: number,
    isBankDetails?: boolean,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      let apiPath = `/invoice/get-${
        previewType === "PI" ? "pi" : "invoice"
      }-preview?invoice_uuid=${uuid}&isPreview=false&IndianStyle=${isIndianStyle}`;
      if (previewType === "INVOICE") {
        apiPath += `&currency=${currency}&conversionRate=${conversionRate}&isBankDetails=${isBankDetails}`;
      }
      const res = await api.get(apiPath, {
        responseType: "arraybuffer",
      });

      dispatch(saveLoaderCompleted());
      // Convert the PDF content into a Blob
      const blob = new Blob([res.data], { type: "application/pdf" });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element and simulate a click to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice.pdf"); // Set the filename for the downloaded file
      document.body.appendChild(link);
      link.click();

      // Clean up by revoking the URL object
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      onCallback(true, res.data.data);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
      onCallback(false);
    }
  };
//=============================== Preview Envelope/ Shipping ===============================
export const fetchEnvelopeOrshippingPreviewAsync =
  (
    uuid: string,
    isEnvelope: boolean,
    onCallback: (isSuccess: boolean, html?: any) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/invoice/get-shipping-envelope-preview?invoice_uuid=${uuid}&isPreview=true&isEnvelope=${isEnvelope}`,
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
      onCallback(false);
    }
  };

export const fetchEnvelopeOrshippingDownloadPdfAsync =
  (
    uuid: string,
    isEnvelope: boolean,
    onCallback: (isSuccess: boolean, html?: any) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.get(
        `/invoice/get-shipping-envelope-preview?invoice_uuid=${uuid}&isPreview=false&isEnvelope=${isEnvelope}`,
        {
          responseType: "arraybuffer",
        },
      );

      dispatch(saveLoaderCompleted());
      // Convert the PDF content into a Blob
      const blob = new Blob([res.data], { type: "application/pdf" });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element and simulate a click to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice.pdf"); // Set the filename for the downloaded file
      document.body.appendChild(link);
      link.click();

      // Clean up by revoking the URL object
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      onCallback(true, res.data.data);
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
      onCallback(false);
    }
  };

//=============================== Clear State ===============================
export const CLEAR_INVOICE = "CLEAR_INVOICE";
export const CLEAR_INVOICE_STATE = "CLEAR_INVOICE_STATE";
export const clearInvoice = () => action(CLEAR_INVOICE);
export const clearInvoiceState = () => action(CLEAR_INVOICE_STATE);
