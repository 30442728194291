import { TextField, TextFieldProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

export const CustomTextField = styled((props: TextFieldProps) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    const preventScroll = (event: WheelEvent) => {
      if (
        inputRef.current === document.activeElement &&
        props.type === "number"
      ) {
        event.preventDefault();
      }
    };

    const inputElement = inputRef.current;
    inputElement?.addEventListener("wheel", preventScroll, { passive: false });

    return () => {
      inputElement?.removeEventListener("wheel", preventScroll);
    };
  }, [props.type]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    // Remove leading zero if the type is number and value starts with '0'
    if (props.type === "number" && value.startsWith("0")) {
      const newValue = value.replace(/^0+/, ""); // Remove leading zeros
      inputRef.current!.value = newValue;
      props.onChange?.({
        ...event,
        target: { ...event.target, value: newValue },
      } as React.ChangeEvent<HTMLInputElement>);
    } else {
      props.onChange?.(event);
    }
  };

  return (
    <TextField {...props} inputRef={inputRef} onChange={handleInputChange} />
  );
})(({ theme }) => ({
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& .MuiOutlinedInput-input": {
    padding: "9px 13px",
    fontSize: "0.8rem",
    color: "rgb(38, 38, 38)",
  },
  "& .MuiOutlinedInput-input::-webkit-input-placeholder": {
    color: "#767e89",
    opacity: "1",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `${
      theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.12)" : "#dee3e9"
    }`,
    borderRadius: "5px",
  },
  "& .MuiOutlinedInput-input.Mui-disabled": {
    backgroundColor: `${
      theme.palette.mode === "dark" ? "rgba(0, 0, 0, 0.12)" : "#f8f9fb"
    }`,
    fontWeight: "900",
  },
  "& .MuiOutlinedInput-input.Mui-disabled::-webkit-input-placeholder": {
    color: "#767e89",
    opacity: "1",
  },
}));
