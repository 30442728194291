import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { getSearchQuery } from "../common/helpers";
import { IQueryParams } from "../common/common.types";
import { uploadFile } from "../../helpers/uploadFile";
import { IProformaInvoice } from "./proformaInvoice.types";

//=============================== Proforma Invoice List ===============================
export const FETCH_PROFORMA_INVOICE_LIST_PROGRESS =
  "FETCH_PROFORMA_INVOICE_LIST_PROGRESS";
export const FETCH_PROFORMA_INVOICE_LIST_SUCCESS =
  "FETCH_PROFORMA_INVOICE_LIST_SUCCESS";
export const FETCH_PROFORMA_INVOICE_LIST_FAILED =
  "FETCH_PROFORMA_INVOICE_LIST_FAILED";

export const fetchProformaInvoiceListProgress = () =>
  action(FETCH_PROFORMA_INVOICE_LIST_PROGRESS);
export const fetchProformaInvoiceListSuccess = (
  list: IProformaInvoice[],
  totalRecords: number,
) => action(FETCH_PROFORMA_INVOICE_LIST_SUCCESS, { list, totalRecords });
export const fetchProformaInvoiceListFailed = () =>
  action(FETCH_PROFORMA_INVOICE_LIST_FAILED);

export const fetchProformaInvoiceListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchProformaInvoiceListProgress());

      const res = await api.get(`/invoice/get-proforma-invoice${searchQuery}`);
      const data: IProformaInvoice[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchProformaInvoiceListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchProformaInvoiceListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message || "Unknown Error Occurred",
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Fetch Single Proforma Invoice ===============================
export const FETCH_PROFORMA_INVOICE_PROGRESS =
  "FETCH_PROFORMA_INVOICE_PROGRESS";
export const FETCH_PROFORMA_INVOICE_SUCCESS = "FETCH_PROFORMA_INVOICE_SUCCESS";
export const FETCH_PROFORMA_INVOICE_FAILED = "FETCH_PROFORMA_INVOICE_FAILED";

export const fetchProformaInvoiceProgress = () =>
  action(FETCH_PROFORMA_INVOICE_PROGRESS);
export const fetchProformaInvoiceSuccess = (data: IProformaInvoice) =>
  action(FETCH_PROFORMA_INVOICE_SUCCESS, { data });
export const fetchProformaInvoiceFailed = (errorMessage: string) =>
  action(FETCH_PROFORMA_INVOICE_FAILED, { errorMessage });

export const fetchProformaInvoiceAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchProformaInvoiceProgress());
      const res = await api.get(
        `/invoice/get-proforma-invoice?proforma_invoice_uuid=${uuid}`,
      );
      const data: IProformaInvoice[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchProformaInvoiceSuccess(data[0]));
      } else {
        dispatch(
          fetchProformaInvoiceFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchProformaInvoiceFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Upsert Proforma Invoice ===============================
export const upsertProformaInvoiceAsync =
  (
    data: IProformaInvoice,
    file: any | null,
    onCallback: (isSuccess: boolean, invoiceUUID?: string) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      let path = "";
      if (file && data.combined_invoice_no) {
        const asPayload = {
          invoice_no: data.combined_invoice_no,
          document_type: data.document_type,
        };
        path = await uploadFile(file, "INVOICE", data.qr_code || "", asPayload);
      }

      const {
        create_ts,
        insert_ts,
        invoice_uuid,
        invoice_no_aux,
        combined_invoice_no,
        combined_proforma_invoice_no,
        invoice_date,
        ack_no,
        ack_date,
        irn,
        delivery_challan_uuid,
        delivery_challan_no,
        delivery_name,
        po_number,
        po_date,
        ...payload
      } = data;
      const res = await api.post("/invoice/upsert-proforma-invoice", {
        ...payload,
        qr_code: path,
      });

      onCallback(true, res.data.data.proforma_invoice_uuid);
      dispatch(
        showMessage({
          type: "success",
          message: "Proforma Invoice saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//=============================== Clear State ===============================
export const CLEAR_PROFORMA_INVOICE = "CLEAR_PROFORMA_INVOICE";
export const CLEAR_PROFORMA_INVOICE_STATE = "CLEAR_PROFORMA_INVOICE_STATE";
export const clearProformaInvoice = () => action(CLEAR_PROFORMA_INVOICE);
export const clearProformaInvoiceState = () =>
  action(CLEAR_PROFORMA_INVOICE_STATE);
