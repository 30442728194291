import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { getSearchQuery } from "../common/helpers";
import { IQueryParams } from "../common/common.types";
import { uploadFile } from "../../helpers/uploadFile";
import { IPurchaseOrder } from "./purchase-order.types";

//=============================== Purchase Order List ===============================
export const FETCH_PURCHASE_ORDER_LIST_PROGRESS =
  "FETCH_PURCHASE_ORDER_LIST_PROGRESS";
export const FETCH_PURCHASE_ORDER_LIST_SUCCESS =
  "FETCH_PURCHASE_ORDER_LIST_SUCCESS";
export const FETCH_PURCHASE_ORDER_LIST_FAILED =
  "FETCH_PURCHASE_ORDER_LIST_FAILED";

export const fetchPurchaseOrderListProgress = () =>
  action(FETCH_PURCHASE_ORDER_LIST_PROGRESS);
export const fetchPurchaseOrderListSuccess = (
  list: IPurchaseOrder[],
  totalRecords: number,
) => action(FETCH_PURCHASE_ORDER_LIST_SUCCESS, { list, totalRecords });
export const fetchPurchaseOrderListFailed = () =>
  action(FETCH_PURCHASE_ORDER_LIST_FAILED);

export const fetchPurchaseOrderListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchPurchaseOrderListProgress());

      const res = await api.get(
        `/purchase_order/get-purchase-order${searchQuery}`,
      );
      const data: IPurchaseOrder[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchPurchaseOrderListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchPurchaseOrderListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message || "Unknown Error Occurred",
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Fetch Single Purchase Order ===============================
export const FETCH_SINGLE_PURCHASE_ORDER_PROGRESS =
  "FETCH_SINGLE_PURCHASE_ORDER_PROGRESS";
export const FETCH_SINGLE_PURCHASE_ORDER_SUCCESS =
  "FETCH_SINGLE_PURCHASE_ORDER_SUCCESS";
export const FETCH_SINGLE_PURCHASE_ORDER_FAILED =
  "FETCH_SINGLE_PURCHASE_ORDER_FAILED";

export const fetchSinglePurchaseOrderProgress = () =>
  action(FETCH_SINGLE_PURCHASE_ORDER_PROGRESS);
export const fetchSinglePurchaseOrderSuccess = (data: IPurchaseOrder) =>
  action(FETCH_SINGLE_PURCHASE_ORDER_SUCCESS, { data });
export const fetchSinglePurchaseOrderFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_PURCHASE_ORDER_FAILED, { errorMessage });

export const fetchSinglePurchaseOrderAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSinglePurchaseOrderProgress());
      const res = await api.get(
        `/purchase_order/get-purchase-order?purchase_order_uuid=${uuid}`,
      );
      const data: IPurchaseOrder[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSinglePurchaseOrderSuccess(data[0]));
      } else {
        dispatch(
          fetchSinglePurchaseOrderFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchSinglePurchaseOrderFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Upsert Single Purchase Order ===============================
export const upsertSinglePurchaseOrderAsync =
  (
    payload: IPurchaseOrder,
    onCallback: (isSuccess: boolean, grnUUID?: string) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      const {
        create_ts,
        insert_ts,
        combined_purchase_order_no,
        ...restPayload
      } = payload;
      const res = await api.post(
        "/purchase_order/upsert-purchase-order",
        restPayload,
      );

      const responseData: IPurchaseOrder = res.data.data;

      onCallback(true, responseData.purchase_order_uuid as string);
      dispatch(
        showMessage({
          type: "success",
          message: "Purchase Order saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//=============================== Delete Single Purchase Order ===============================
export const deleteSinglePurchaseOrderAsync =
  (
    uuid: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.delete("/purchase_order/delete-purchase-order", {
        data: { purchase_order_uuid: uuid },
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Purchase Order deleted successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//=============================== Clear State ===============================
export const CLEAR_SINGLE_PURCHASE_ORDER = "CLEAR_SINGLE_PURCHASE_ORDER";
export const CLEAR_PURCHASE_ORDER_STATE = "CLEAR_PURCHASE_ORDER_STATE";
export const clearPurchaseOrderSync = () => action(CLEAR_SINGLE_PURCHASE_ORDER);
export const clearPurchaseOrderStateSync = () =>
  action(CLEAR_PURCHASE_ORDER_STATE);

// //#######################################################################################
// //############################ Purchase Order Analytics #################################
// //#######################################################################################
// export const FETCH_PURCHASE_ORDER_ANALYTICS_LIST_PROGRESS = "FETCH_PURCHASE_ORDER_ANALYTICS_LIST_PROGRESS";
// export const FETCH_PURCHASE_ORDER_ANALYTICS_LIST_SUCCESS = "FETCH_PURCHASE_ORDER_ANALYTICS_LIST_SUCCESS";
// export const FETCH_PURCHASE_ORDER_ANALYTICS_LIST_FAILED = "FETCH_PURCHASE_ORDER_ANALYTICS_LIST_FAILED";

// export const fetchPurchaseOrderAnalyticsListProgress = () =>
//   action(FETCH_PURCHASE_ORDER_ANALYTICS_LIST_PROGRESS);
// export const fetchPurchaseOrderAnalyticsListSuccess = (data: IAnalytics[]) =>
//   action(FETCH_PURCHASE_ORDER_ANALYTICS_LIST_SUCCESS, { data });
// export const fetchPurchaseOrderAnalyticsListFailed = () =>
//   action(FETCH_PURCHASE_ORDER_ANALYTICS_LIST_FAILED);

// export const fetchPurchaseOrderAnalyticsWithParamsAsync =(queryParams: {
//     from_data: string;
//     to_date: string;
//   }): ThunkAction<void, IStoreState, {}, AnyAction> =>
//     async (dispatch, getState) => {
//       try {
//         dispatch(fetchPurchaseOrderAnalyticsListProgress());
//         let finalUrl = `/analytics/get-purchase-order-analytics?from_date=${queryParams.from_data}&to_date=${queryParams.to_date}`;
//         const res = await api.get(finalUrl);
//         const data: IAnalytics[] = res.data.data;
//         dispatch(fetchPurchaseOrderAnalyticsListSuccess(data));
//       } catch (err: any) {
//         dispatch(fetchPurchaseOrderAnalyticsListFailed());
//         dispatch(
//           showMessage({
//             type: "error",
//             message: err.response.data.message,
//             displayAs: "snackbar",
//           }),
//         );
//       }
//     };

// export const CLEAR_PURCHASE_ORDER_ANALYTICS = "CLEAR_PURCHASE_ORDER_ANALYTICS";

// export const clearPurchaseOrderAnalyticsSync = () => action(CLEAR_PURCHASE_ORDER_ANALYTICS);
